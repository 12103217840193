import { TbCalendarTime } from 'react-icons/tb';
import { Icon } from '@chakra-ui/react'
import {
    ChakraProvider,
    Box,
    Heading,
    SimpleGrid,
    Image,
    Flex,
    Text,
    Button,
    Center,
    Card,
    CardBody,
    Stack,
    Divider,
    CardFooter,
    Tabs,
    TabList,
    TabIndicator,
    TabPanel,
    Tab,
    TabPanels,
    Tooltip,
    StackDivider,
    Badge,
    ButtonGroup
  } from "@chakra-ui/react";
import { css } from "@emotion/react";

const date = new Date();
const hour = date.getHours();
var minutes = date.getMinutes().toString();
if (minutes.length === 1) minutes = '0' + minutes;
const currentHour = hour + ':' + minutes;
  
function BoxAttractions(props) {
    return (        
        <Card maxW='sm'>
            <CardBody>
                <Image
                src={props.attr.thumbMedia.url}
                alt='Show picture'
                borderRadius='lg'
                />
                <Stack mt='3' spacing='3'>
                    <Heading size='md'>{props.attr.name}</Heading>
                </Stack>
            </CardBody>
            <Divider />
            <CardFooter>
                <SimpleGrid columns={5} spacing={2}>
                    {props.attr.schedules.map((play) => {
                        return(
                        <Badge variant='solid' colorScheme='green' fontSize="sm">
                            <Center>
                                <Icon as={TbCalendarTime} boxSize={4} />&nbsp;
                                {play.startTime.slice(0,-3)}
                            </Center>
                        </Badge>
                        );
                    })}
               </SimpleGrid>
            </CardFooter>
        </Card>
    );
}
  
  function P2Shows(props) {
    const responsiveStyles = css`
        flex-wrap: wrap;
    `;
  
    const P2shows = [];
  
    for (var show in props.showList) {
      if (props.showList[show].location.id === "P2") {
        P2shows.push(props.showList[show]);
      }
    }
  
    P2shows.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  
    return (
        <Stack direction="row" spacing={4} justify="center" css={responsiveStyles}>
            {P2shows.map((attr) => {
                return (
                <BoxAttractions attr={attr} />
                );
            })}
        </Stack>
    );
  };
  
  function P1Shows(props) {
    const responsiveStyles = css`
        flex-wrap: wrap;
    `;
  
    const P1shows = [];
  
    for (var show in props.showList) {
      if (props.showList[show].location.id === "P1") {
        P1shows.push(props.showList[show]);
      }
    }
  
    P1shows.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  
    return (
        <Stack direction="row" spacing={4} justify="center" css={responsiveStyles}>
            {P1shows.map((attr) => {
                return (
                <BoxAttractions attr={attr} />
                );
            })}
        </Stack>
    );
  };
  
  function AllShows(props) {
    const responsiveStyles = css`
        flex-wrap: wrap;
    `;
  
    props.showList.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  
    return (
        <Stack direction="row" spacing={4} justify="center" css={responsiveStyles}>
            {props.showList.map((attr) => {
                return (
                <BoxAttractions attr={attr} />
                );
            })}
        </Stack>
    );
  }
  
 export function Shows(props) {
    const responsiveStyles = css`
      @media screen and (min-width: 850px) {
        margin-left: 30%;
        margin-right: 30%;
      }
    `;
  
    const showList = [];
    for (var show in props.data.data.activitySchedules) {
      if (props.data.data.activitySchedules[show].type === "Entertainment" && props.data.data.activitySchedules[show].subType !== "Character Experience - Meet & Greet") {
        showList.push(props.data.data.activitySchedules[show]);
      }
    }
  
    return (
      <>
        <Tabs position="relative" variant="unstyled" align="center" color="white">
          <TabList>
            <Tab>Tout</Tab>
            <Tab>Parc Disneyland</Tab>
            <Tab>Parc Walt Disney Studios</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="white"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel paddingLeft="0" paddingRight="0">
              <AllShows showList={showList} />
            </TabPanel>
            <TabPanel paddingLeft="0" paddingRight="0">
              <P1Shows showList={showList} />
            </TabPanel>
            <TabPanel paddingLeft="0" paddingRight="0">
              <P2Shows showList={showList} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    );
  }