import '../../App.css';
import {
  ChakraProvider,
  Box,
  Heading,
  Image,
  Text,
  Center,
  Card,
  CardBody,
  Stack,
  Divider,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  TabPanels,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDate, getDateInverted } from '../../components/date';
import { TbCalendarTime } from 'react-icons/tb';
import { Icon } from '@chakra-ui/react'
import { Attractions } from './attractions';
import { Shows } from './shows';


function InformationChoice(props) {
  return (
    <Tabs variant="solid-rounded" align="center" colorScheme='whiteAlpha' marginTop="10px">
      <TabList>
        <Tab color="white">Attractions</Tab>
        <Tab color="white">Spectacles</Tab>
        <Tab color="white">Pass annuels</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Attractions data={props.data} waitTimes={props.waitTimes} />
        </TabPanel>
        <TabPanel>
          <Shows data={props.data} waitTimes={props.waitTimes} />
        </TabPanel>
        <TabPanel>
          <Center>
            <Card>
              <CardBody>
                <Text fontSize='lg'>En construction</Text>
                <iframe title="soon" src="https://lottie.host/embed/b5992adb-38eb-4e4e-8644-c2c83fd93df8/EHQDNgscNQ.json"></iframe>
              </CardBody>
            </Card>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

function Dlp() {
  const [data, setData] = useState(null);
  const [waitTimes, setWaitTimes] = useState(null);

  const rawdata = {
    "operationName":"activitySchedules",
    "variables": {
        "market":"fr-fr",
        "types":[{"type":"ThemePark","status":["OPERATING","EXTRA_MAGIC_HOURS"]},{"type":"Attraction","status":["REFURBISHMENT","OPERATING","CLOSED_OPS"]},{"type":"Entertainment","status":"PERFORMANCE_TIME"}],
        "date": getDateInverted()
    },
    "query":"query activitySchedules($market: String!, $types: [ActivityScheduleStatusInput]!, $date: String!) {\n  activitySchedules(market: $market, date: $date, types: $types) {\n    id\n    name\n    url\n    urlFriendlyId\n    hideFunctionality\n    shortDescription\n    iconFont\n    subType\n    thumbMedia {\n      url\n      alt\n      __typename\n    }\n    subLocation {\n      ...location\n      __typename\n    }\n    location {\n      ...location\n      __typename\n    }\n    type\n    schedules(date: $date, types: $types) {\n      startTime\n      endTime\n      date\n      status\n      closed\n      language\n      __typename\n    }\n    __typename\n  }\n}\n\nfragment location on Location {\n  id\n  value\n  urlFriendlyId\n  iconFont\n  url\n  __typename\n}\n"};

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Spécifiez le type de contenu JSON si nécessaire
    },
    body: JSON.stringify(rawdata), // Convertit les données en JSON
  };

  const requestOptions2 = {
    method: 'GET',
    headers: {
      'postman-token': '3746d25e-0bad-4be0-b333-ccb1495dd572',
      'x-api-key': '3jPT5qMimN3kR2kxqd1ez9iF1C68CrBf7zw5ICo4',
      'Origin': 'http://localhost:3000/dlp',
    },
  }; 

  const responsiveStyles = css`
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  `;


  useEffect(() => {
    fetch('https://aws-api.disneylandparis.com/query', requestOptions)
      .then(response => response.json())
      .then(data => {
        // Traitez les données ici
        setData(data);
      })
      .catch(error => {
        // Gérez les erreurs ici
        console.error(error);
      });
    fetch('https://cors-anywhere.herokuapp.com/https://dlp-wt.wdprapps.disney.com/prod/v1/waitTimes', requestOptions2)
      .then(response => response.json())
      .then(data => {
        // Traitez les données ici
        var attractionWaitTime = {};
        for (const attraction of data) {
            const attractionId = attraction["entityId"];
            attractionWaitTime[attractionId] = attraction;
        }
        setWaitTimes(attractionWaitTime);
      })
      .catch(error => {
        // Gérez les erreurs ici
        console.error(error);
      });
  },[]);

  return (
    <div className="home">
      <ChakraProvider>
        <Box className="App">
          <Image
            src="/img/general/parks inside.png"
            alt=""
            borderRadius="lg"
            maxW="200px"
            mx="auto"
          />
        </Box>
        {data && waitTimes ? (
          <>
            <Heading align="center" color="white" size="lg" margin="10px">Disneyland Paris</Heading>
            <Heading align="center" color="white" size="sm" margin="5px">{getDate()}</Heading>
            <Stack direction="row" spacing={10} justify="center" css={responsiveStyles}>
              <div>
                <Heading align="center" color="white" size="sm">Parc Disneyland : {data.data.activitySchedules.find(item => item.id === "P1").schedules.find(item => item.status !== "EXTRA_MAGIC_HOURS").startTime.slice(0,-3)} - {data.data.activitySchedules.find(item => item.id === "P1").schedules.find(item => item.status !== "EXTRA_MAGIC_HOURS").endTime.slice(0,-3)}</Heading>
                <Heading align="center" color="white" size="xs">
                  <Tooltip label="Extra Magic Time : accès anticipé pour les guests hôtels et Pass Annuels Infinity, Magic Plus et Disneyland Pass Gold" aria-label='A tooltip'>
                    EMT
                  </Tooltip>
                  : {data.data.activitySchedules.find(item => item.id === "P1").schedules.find(item => item.status === "EXTRA_MAGIC_HOURS").startTime.slice(0,-3)} - {data.data.activitySchedules.find(item => item.id === "P1").schedules.find(item => item.status === "EXTRA_MAGIC_HOURS").endTime.slice(0,-3)}
                </Heading>
              </div>
              <div>
                <Heading align="center" color="white" size="sm">Parc Walt Disney Studios : {data.data.activitySchedules.find(item => item.id === "P2").schedules.find(item => item.status !== "EXTRA_MAGIC_HOURS").startTime.slice(0,-3)} - {data.data.activitySchedules.find(item => item.id === "P2").schedules.find(item => item.status !== "EXTRA_MAGIC_HOURS").endTime.slice(0,-3)}</Heading>
                <Heading align="center" color="white" size="xs">
                  <Tooltip label="Extra Magic Time : accès anticipé pour les guests hôtels et Pass Annuels Infinity, Magic Plus et Disneyland Pass Gold" aria-label='A tooltip'>
                    EMT
                  </Tooltip>
                  : {data.data.activitySchedules.find(item => item.id === "P2").schedules.find(item => item.status === "EXTRA_MAGIC_HOURS").startTime.slice(0,-3)} - {data.data.activitySchedules.find(item => item.id === "P2").schedules.find(item => item.status === "EXTRA_MAGIC_HOURS").endTime.slice(0,-3)}
                </Heading>
              </div>
            </Stack>
            <Divider />
            <InformationChoice data={data} waitTimes={waitTimes} />
          </>
          ) : (
            <Center>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
        )}
      </ChakraProvider>
    </div>
  );
}

export default Dlp;
