import { TbCalendarTime } from 'react-icons/tb';
import { Icon } from '@chakra-ui/react'
import {
    Box,
    Heading,
    Text,
    Center,
    Card,
    CardBody,
    Stack,
    Tabs,
    TabList,
    TabIndicator,
    TabPanel,
    Tab,
    TabPanels,
    Tooltip,
    StackDivider,
    Badge,
  } from "@chakra-ui/react";
import { css } from "@emotion/react";

const date = new Date();
const hour = date.getHours();
var minutes = date.getMinutes().toString();
if (minutes.length === 1) minutes = '0' + minutes;
const currentHour = hour + ':' + minutes;

function BoxAttractionsClosed(props) {

    return (
      <Box>
        <Stack direction="row" spacing={4}>
          <Heading size='sm' textTransform='uppercase'>
            {props.attr.name}
          </Heading>
          <Box ml="auto">
            <Tooltip label="Fermé aujourd'hui" aria-label='A tooltip'>
              <Badge variant='solid' colorScheme='red' fontSize="sm">
                <Icon as={TbCalendarTime} boxSize={4} />&nbsp;
                Fermé
              </Badge>
            </Tooltip>
          </Box>
        </Stack>
      </Box>
    );
  }
  
  function BoxAttractions(props) {
    console.log(props);
    var waitTimes = props.waitTimes[props.attr.id];
    console.log(waitTimes);
  
    return (
      <Box>
        <Stack direction="row" spacing={4}>
          <Heading size='sm' textTransform='uppercase'>
            {props.attr.name}
          </Heading>
          <Box ml="auto">
            <Tooltip label="Temps d'attente" aria-label='A tooltip'>
              <Badge variant='solid' colorScheme='gray' fontSize="sm">
                <Text fontSize='sm'>
                  {waitTimes !== undefined ? (
                    <>{waitTimes.postedWaitMinutes} min</>
                  ) : (
                    <>0 min</>
                  )}
                </Text>
              </Badge>
            </Tooltip>
          </Box>
          {parseInt(currentHour.replace(':', '')) > parseInt(props.attr.schedules[0].startTime.slice(0,-3).replace(':', '')) ? (parseInt(currentHour.replace(':', '')) < parseInt(props.attr.schedules[0].endTime.slice(0,-3).replace(':', '')) ? (
            <Box ml="auto" marginLeft="0">
              <Tooltip label="Horaires d'ouverture" aria-label='A tooltip'>
                <Badge variant='solid' colorScheme='green' fontSize="sm">
                    <Center>
                        <Icon as={TbCalendarTime} boxSize={4} />&nbsp;
                        {props.attr.schedules[0].startTime.slice(0,-3)} à {props.attr.schedules[0].endTime.slice(0,-3)}
                    </Center>
                </Badge>
              </Tooltip>
            </Box>
          ) : (
            <Box ml="auto" marginLeft="0">
              <Tooltip label="Horaires prévisionnels d'ouverture" aria-label='A tooltip'>
                <Badge variant='solid' colorScheme='red' fontSize="sm">
                    <Center>
                        <Icon as={TbCalendarTime} boxSize={4} />&nbsp;
                        {props.attr.schedules[0].startTime.slice(0,-3)} à {props.attr.schedules[0].endTime.slice(0,-3)}
                    </Center>
                </Badge>
              </Tooltip>
            </Box>
          )) : (
            <Box ml="auto" marginLeft="0">
              <Tooltip label="Horaires prévisionnels d'ouverture" aria-label='A tooltip'>
                <Badge variant='solid' colorScheme='red' fontSize="sm">
                    <Center>
                        <Icon as={TbCalendarTime} boxSize={4} />&nbsp;
                        {props.attr.schedules[0].startTime.slice(0,-3)} à {props.attr.schedules[0].endTime.slice(0,-3)}
                    </Center>
                </Badge>
              </Tooltip>
            </Box>
          )}
        </Stack>
      </Box>
    );
  }
  
  function P2Attractions(props) {
    const responsiveStyles = css`
      @media screen and (min-width: 850px) {
        margin-left: 10%;
        margin-right: 10%;
      }
    `;
  
    var P2attractionListOpen = [];
    var P2attractionListClose = [];
  
    for (var attraction in props.attractionListOpen) {
      if (props.attractionListOpen[attraction].location.id === "P2") {
        P2attractionListOpen.push(props.attractionListOpen[attraction]);
      }
    }
  
    for (var attraction2 in props.attractionListClose) {
      if (props.attractionListClose[attraction2].location.id === "P2") {
        P2attractionListClose.push(props.attractionListClose[attraction2]);
      }
    }
    P2attractionListOpen.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  
    P2attractionListClose.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  
    return (
      <>
        <Card css={responsiveStyles}>
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
            {P2attractionListOpen.map((attr) => {
              return (
                <BoxAttractions attr={attr} waitTimes={props.waitTimes} />
              );
            })}
            </Stack>
          </CardBody>
        </Card>
        <Heading align="center" color="white" size="md" margin="10px">Fermé aujourd'hui</Heading>
        <Card css={responsiveStyles}>
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
            {P2attractionListClose.map((attr) => {
              return (
                <BoxAttractionsClosed attr={attr} />
              );
            })}
            {P2attractionListClose.length === 0 && (
              <>
                Aucune attraction fermée
              </>
            )}
            </Stack>
          </CardBody>
        </Card>
      </>
    );
  };
  
  function P1Attractions(props) {
    const responsiveStyles = css`
      @media screen and (min-width: 850px) {
        margin-left: 10%;
        margin-right: 10%;
      }
    `;
  
    const P1attractionListOpen = [];
    const P1attractionListClose = [];
  
    for (var attraction in props.attractionListOpen) {
      if (props.attractionListOpen[attraction].location.id === "P1") {
        P1attractionListOpen.push(props.attractionListOpen[attraction]);
      }
    }
  
    for (var attraction2 in props.attractionListClose) {
      if (props.attractionListClose[attraction2].location.id === "P1") {
        P1attractionListClose.push(props.attractionListClose[attraction2]);
      }
    }
  
    P1attractionListOpen.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  
    P1attractionListClose.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  
    return (
      <>
        <Card css={responsiveStyles}>
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
            {P1attractionListOpen.map((attr) => {
              return (
                <BoxAttractions attr={attr} waitTimes={props.waitTimes} />
              );
            })}
            </Stack>
          </CardBody>
        </Card>
        <Heading align="center" color="white" size="md" margin="10px">Fermé aujourd'hui</Heading>
        <Card css={responsiveStyles}>
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
            {P1attractionListClose.map((attr) => {
              return (
                <BoxAttractionsClosed attr={attr} />
              );
            })}
            {P1attractionListClose.length === 0 && (
              <>
                Aucune attraction fermée
              </>
            )}
            </Stack>
          </CardBody>
        </Card>
      </>
    );
  };
  
  function AllAttractions(props) {
    const responsiveStyles = css`
      @media screen and (min-width: 850px) {
        margin-left: 10%;
        margin-right: 10%;
      }
    `;
  
    props.attractionListOpen.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    console.log(props);
  
    return (
      <>
        <Card css={responsiveStyles}>
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
            {props.attractionListOpen.map((attr) => {
              return (
                <BoxAttractions attr={attr} waitTimes={props.waitTimes} />
              );
            })}
            </Stack>
          </CardBody>
        </Card>
        <Heading align="center" color="white" size="md" margin="10px">Fermé aujourd'hui</Heading>
        <Card css={responsiveStyles}>
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
            {props.attractionListClose.map((attr) => {
              return (
                <BoxAttractionsClosed attr={attr} />
              );
            })}
            {props.attractionListClose.length === 0 && (
              <>
                Aucune attraction fermée
              </>
            )}
            </Stack>
          </CardBody>
        </Card>
      </>
    );
  }
  
 export function Attractions(props) {
  
    const attractionListOpen = [];
    const attractionListClose = [];
    for (var attraction in props.data.data.activitySchedules) {
      if (props.data.data.activitySchedules[attraction].type === "Attraction" && props.data.data.activitySchedules[attraction].schedules[0].status !== "REFURBISHMENT") {
        attractionListOpen.push(props.data.data.activitySchedules[attraction]);
      } else if (props.data.data.activitySchedules[attraction].type === "Attraction" && props.data.data.activitySchedules[attraction].schedules[0].status === "REFURBISHMENT" && props.data.data.activitySchedules[attraction].hideFunctionality !== "Hide from the Service") {
        attractionListClose.push(props.data.data.activitySchedules[attraction]);
      }
    }
  
    return (
      <>
        <Tabs position="relative" variant="unstyled" align="center" color="white">
          <TabList>
            <Tab>Tout</Tab>
            <Tab>Parc Disneyland</Tab>
            <Tab>Parc Walt Disney Studios</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="white"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel paddingLeft="0" paddingRight="0">
              <AllAttractions attractionListOpen={attractionListOpen} attractionListClose={attractionListClose} waitTimes={props.waitTimes} />
            </TabPanel>
            <TabPanel paddingLeft="0" paddingRight="0">
              <P1Attractions attractionListOpen={attractionListOpen} attractionListClose={attractionListClose} waitTimes={props.waitTimes} />
            </TabPanel>
            <TabPanel paddingLeft="0" paddingRight="0">
              <P2Attractions attractionListOpen={attractionListOpen} attractionListClose={attractionListClose} waitTimes={props.waitTimes} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    );
  }