import './App.css';
import {
  ChakraProvider,
  Box,
  Heading,
  Image,
  Text,
  Button,
  Center,
  Card,
  CardBody,
  Stack,
  Divider,
  CardFooter,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { Link } from 'react-router-dom';

function ParkCard(props) {
  return (
    <Link to={props.link}>
      <Card maxW={{ base: "100%", sm: "sm" }} marginTop="30px" minHeight="480px" className={props.css}>
        <CardBody>
          <Image
            src={props.img}
            alt=""
            borderRadius="lg"
            maxW="100%"
            mx="auto"
          />
          <Stack mt="6" spacing="3">
            <Heading size="md">{props.title}</Heading>
            <Text>{props.desc}</Text>
          </Stack>
        </CardBody>
        <Divider />
        <Center>
          <CardFooter>
            <Button variant='outline' colorScheme="white">
              J'y vais !
            </Button>
          </CardFooter>
        </Center>
      </Card>
    </Link>
  );
}

function App() {
  const responsiveStyles = css`
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  `;

  return (
    <div className="home">
      <ChakraProvider>
        <Box className="App">
          <Image
            src="/img/general/parks inside.png"
            alt=""
            borderRadius="lg"
            maxW="200px"
            mx="auto"
          />
          <Stack direction="row" spacing={4} justify="center" css={responsiveStyles}>
            <ParkCard
              img="/img/dlp/banner.png"
              title="Disneyland Paris"
              desc="Parc Disneyland et Parc Walt Disney Studios. Temps d'attente, horaires, spectacles, disponibilités pass annuels..."
              css="ParkCard ParkCardDlp"
              link="/dlp"
            />
            <ParkCard
              img="/img/asterix/banner.png"
              title="[WIP] Parc Astérix"
              desc="Le plus gaulois des parcs. Temps d'attente, horaires, spectacles..."
              css="ParkCard ParkCardAsterix"
              link=""
            />
          </Stack>
        </Box>
      </ChakraProvider>
    </div>
  );
}

export default App;
