export function getDate() {
    const date = new Date();

    // Obtenez le jour, le mois et l'année
    const jour = String(date.getDate()).padStart(2, '0');
    const mois = String(date.getMonth() + 1).padStart(2, '0');
    const annee = date.getFullYear();

    const dateFormatee = `${jour}/${mois}/${annee}`;

    return dateFormatee;
}

export function getDateInverted() {
    const date = new Date();

    // Obtenez le jour, le mois et l'année
    const jour = String(date.getDate()).padStart(2, '0');
    const mois = String(date.getMonth() + 1).padStart(2, '0');
    const annee = date.getFullYear();

    const dateFormatee = `${annee}-${mois}-${jour}`;

    return dateFormatee;
} 