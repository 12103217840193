import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Dlp from './parks/dlp/home';
import App from './App';
 
ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Routes>
              <Route path="/" element={<App/>} />
              <Route path="/dlp" element={<Dlp/>} />
          </Routes>
      </Router>
  </React.StrictMode>,
document.getElementById('root')
)